'use client'
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useRouter } from 'next/navigation';

export default function NotFound() {

	const router = useRouter()
	return <section className='p-2'>
		<div className='grid grid-cols-1 bg-gradient-to-r from-slate-900 to-gray-900  gap-4 min-w-[340px] bg-slate-900 p-2 rounded-md border border-slate-600 shadow shadow-md shadow-slate-600'>
			<div className='text-orange-400 animate-pulse justify-items-center text-xl'>
				<h1>Whoops, sorry about that!</h1>
				<p>
					The page you were looking for was not found...
				</p>
			</div>
			<button type="button" onClick={() => router.back()} role='button' className='defaultButtonStyling'>
				Here, you can easily get back by clicking me! <ArrowBackIcon />
			</button>
		</div>
	</section>
}